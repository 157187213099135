<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-text
        v-model="form.codigo"
        :class="{
          'col-12 col-md-3': participanteLogadoConta,
          'col-12 col-md-8': !participanteLogadoConta,
        }"
        :label="$t('modulos.acreditacao.formulario.codigo')"
        obrigatorio
        :max="20"
      />
      <input-select
        v-if="participanteLogadoConta"
        v-model="form.participanteContaId"
        :options="opcoes.participanteConta"
        :loading="loadingParticipanteConta"
        class="col-12 col-md-3"
        :label="$t('modulos.acreditacao.formulario.nomeParticipanteConta')"
        obrigatorio
      />
      <input-file
        :value="form.fileImagemCabecalho"
        accept="image/png"
        class="col-12 col-md-3"
        returns="base64"
        :placeholder="$t('geral.inputs.selecione')"
        :label="$t('modulos.acreditacao.formulario.imagem_cabecalho')"
        :regras-personalizadas="[regraArquivo]"
        visualizador
        @clear="desvincularImagem('imagemCabecalhoCertificado', 'nomeArquivoImagemCabecalhoCertificado')"
        @nome-imagem="nomeImagem('nomeArquivoImagemCabecalhoCertificado', $event)"
        @handle-upload="handlerUpload('imagemCabecalhoCertificado', $event)"
      />
      <input-file
        :value="form.fileImagemRodape"
        accept="image/png"
        class="col-12 col-md-3"
        returns="base64"
        :placeholder="$t('geral.inputs.selecione')"
        :label="$t('modulos.acreditacao.formulario.imagem_rodape')"
        :regras-personalizadas="[regraArquivo]"
        visualizador
        @clear="desvincularImagem('imagemRodapeCertificado', 'nomeArquivoImagemRodapeCertificado')"
        @nome-imagem="nomeImagem('nomeArquivoImagemRodapeCertificado', $event)"
        @handle-upload="handlerUpload('imagemRodapeCertificado', $event)"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>

        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import AcreditacaoService from '@/common/services/cadastros/AcreditacaoService.js';
import ParticipanteService from '@/common/services/cadastros/ParticipanteService.js';
import { AcreditacaoModel } from '@/common/models/cadastros/AcreditacaoModel.js';
import { InputFile } from '@/components/inputs';
import { mapGetters } from 'vuex';
import helpers from '@common/utils/helpers';
export default {
  components: { InputFile },
  props: {
    id: { type: String, default: null },
  },
  data() {
    return {
      valido: false,
      form: new AcreditacaoModel({}),
      opcoes: {
        participanteConta: [],
      },
      loadingParticipanteConta: false,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
    tituloFormulario() {
      if (this.id) return this.$t('modulos.acreditacao.titulos.editar');
      return this.$t('modulos.acreditacao.titulos.novo');
    },
    tooltipBotaoSalvar() {
      if (this.valido) return '';
      return this.$t('modulos.acreditacao.validacoes.formulario_invalido');
    },
  },

  mounted() {
    this.buscarParticipanteConta();
    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'Acreditacao', 'Editar');
    }
    helpers.redirecionarSemPermissao(this, 'Acreditacao', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AcreditacaoService.buscar(this.id)
        .then((res) => {
          this.form = new AcreditacaoModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.acreditacao.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarParticipanteConta() {
      this.loadingParticipanteConta = true;
      ParticipanteService.listar({ flagParticipanteConta: true }).then(
        (res) => {
          this.opcoes.participanteConta = new DropdownModel(res.data.items, 'apelido');
        }
      );
      this.loadingParticipanteConta = false;
    },
    confirmacaoSalvar() {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar() {
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('Layout/iniciarCarregamento');

      if (!this.participanteLogadoConta)
        this.form.participanteContaId = this.participanteSelecionado;
      AcreditacaoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.acreditacao.cadastro_sucesso`));
          this.$router.push({ name: 'acreditacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'acreditacao' });
      });
    },
    handlerUpload(field, file) {
      this.form[field] = file;
    },
    desvincularImagem(fileField, nameField) {
      this.form[fileField] = null;
      this.form[nameField] = null;
    },
    nomeImagem(nameField, nomeImagem) {
      this.form[nameField] = nomeImagem
      ? nomeImagem
      : null;
    },
    regraArquivo(v) {
      if (!v) return true;
      if (v?.type == 'image/png') return true;
      return this.$t(`modulos.colaborador.validacoes.permitido_png`);
    },
  },
};
</script>
