import helpers from '../../../common/utils/helpers';

export class AcreditacaoModel {
  constructor({
    id,
    codigo,
    participanteContaId,
    imagemCabecalhoCertificado,
    nomeArquivoImagemCabecalhoCertificado,
    imagemRodapeCertificado,
    nomeArquivoImagemRodapeCertificado
  }) {
    this.id = id;
    this.codigo = codigo;
    this.participanteContaId = participanteContaId;
    this.fileImagemCabecalho = null;
    this.fileImagemRodape = null;
    this.imagemCabecalhoCertificado = imagemCabecalhoCertificado;
    this.nomeArquivoImagemCabecalhoCertificado = nomeArquivoImagemCabecalhoCertificado;
    this.imagemRodapeCertificado = imagemRodapeCertificado;
    this.nomeArquivoImagemRodapeCertificado = nomeArquivoImagemRodapeCertificado;

    if (imagemCabecalhoCertificado && nomeArquivoImagemCabecalhoCertificado) {
      this.convertBase64ToFile(imagemCabecalhoCertificado, nomeArquivoImagemCabecalhoCertificado, 'fileImagemCabecalho');
    }

    if (imagemRodapeCertificado && nomeArquivoImagemRodapeCertificado) {
      this.convertBase64ToFile(imagemRodapeCertificado, nomeArquivoImagemRodapeCertificado, 'fileImagemRodape');
    }
  }

  convertBase64ToFile(base64, fileName, field) {
    let ctx = this;
    helpers
      .converterBase64ParaFile(base64, fileName, 'image/png')
      .then(function (file) {
        ctx[field] = file;
      });
  }
}
